<template>
  <div :class="['basiclayoutContainer', fixtopNav && 'fixtopNav']">
    <!-- <NavBar
      :title="$route.meta.title"
      left-arrow
      @click-left="onClickLeft"
      fixed
      placeholder
    ></NavBar> -->
    <router-view />
  </div>
</template>

<script>
import { NavBar } from 'vant';

export default {
  components: {
    NavBar
  },
  data() {
    return {
      fixtopNav: false
    };
  },
  watch: {
    $route: {
      handler() {
        console.log(this.$route);
        this.fixtopNav = this.$route.name === 'LotteryDraw';
      },
      immediate: true
    }
  },
  methods: {
    onClickLeft() {
      this.$router.back();
    }
  }
};
</script>

<style lang="less">
.basiclayoutContainer {
  min-height: 100%;
  background: #f5f5f7;

  .van-nav-bar .van-icon {
    color: #333;
  }

  .van-nav-bar {
    background: transparent;
  }

  &.fixtopNav {
    .van-nav-bar .van-icon {
      color: #fff;
    }

    .van-nav-bar {
      background: transparent;
    }
    .van-nav-bar__title {
      color: #fff;
    }
    // .lotteryDrawContainer {
    //   margin-top: -46px;
    // }
    .van-hairline--bottom::after {
      border: none;
    }
  }
}
</style>
