import firstPrize from '@/assets/img/firstPrize.png';
import firstPrizeGray from '@/assets/img/firstPrizeGray.png';
import secondPrize from '@/assets/img/secondPrize.png';
import secondPrizeGray from '@/assets/img/secondPrizeGray.png';
import thirdPrize from '@/assets/img/thirdPrize.png';
import thirdPrizeGray from '@/assets/img/thirdPrizeGray.png';
import store from '@/store/index';
import { getChatCallback } from '@/utils/api';

export const prize = {
  level1: {
    level: 1,
    image: firstPrize,
    imagegray: firstPrizeGray,
    name: '华为无线充电器',
    title: '一等奖'
  },
  level2: {
    level: 2,
    image: secondPrize,
    imagegray: secondPrizeGray,
    name: '小熊玩偶',
    title: '二等奖'
  },
  level3: {
    level: 3,
    image: thirdPrize,
    imagegray: thirdPrizeGray,
    name: '小电风扇',
    title: '三等奖'
  },
  level4: {
    level: 4,
    image: '',
    imagegray: '',
    name: '谢谢参与',
    title: '参与奖'
  }
};

export const getUrlParam = (key) => {
  const href = window.location.href;
  // const href =
  //   'http://lucky.x.bizfocus.net/?code=071rjy1w3c9iA13apG1w3nbaL72rjy1F&state=123';
  const url = href.split('?');
  if (url.length <= 1) {
    return '';
  }
  const params = url[1].split('&');

  for (let i = 0; i < params.length; i++) {
    const param = params[i].split('=');
    if (key === param[0]) {
      return param[1];
    }
  }
};

export const WXaccredit = async () => {
  const code = getUrlParam('code');
  if (code == null || code === '') {
    const appid = 'wxe242dcd986bddacd';
    const redirectUri = encodeURIComponent(
      'http://lotterydraw.visualticket.cn'
    );
    const state = 123;
    localStorage.removeItem('openid');
    localStorage.removeItem('phone');
    const redirectURI = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirectUri}&response_type=code&scope=snsapi_userinfo&state=${state}#wechat_redirect`;
    window.location.href = redirectURI;
  } else {
    if (code && !localStorage.getItem('openid')) {
      const resp = await getChatCallback({
        code,
        pid: 0
      });
      console.log(resp);
      const result = resp.result || {};
      result.openid && localStorage.setItem('openid', result.openid);
      store.dispatch('setCode', { code: result.openid });
    }
  }
};
