import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    code: '',
    nickname: ''
  },
  getters: {},
  mutations: {
    setCode(state, payload) {
      state.code = payload.code;
    },
    setNickName(state, payload) {
      state.nickname = payload.nickname;
    }
  },
  actions: {
    setCode(context, payload) {
      context.commit('setCode', payload);
    },
    setNickName(context, payload) {
      context.commit('setNickName', payload);
    }
  },
  modules: {}
});
