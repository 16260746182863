import { get, post } from './request';

// 用户授权
export const getChatCallback = (data) => get('/chat/callback', data);

// 是否已经绑定过手机号
export const getIsBindPhone = (data) =>
  post(`/sys/user/has-phone?openid=${data}`, data);

// 登录授权
export const getSmsCode = (data) => post('/sys/user/login-with-sms', data);

// 发送验证码
export const postSignin = (data) => post(`/sys/user/send-sms?phone=${data}`);

// 开始抽奖
export const postStartDraw = (data) => post(`/draw/start?openid=${data}`);

// 我的奖品
export const getMyPrizeList = (data) => post(`/draw/my-prize?openid=${data}`);

// 确认领取
export const postReceiveDraw = (data) => post(`/draw/receive?openid=${data}`);

// 获取配置
export const getDict = (params) => get('/sys/dict/get-dict', params);

// 提交预约
export const submitBook = (data) => post('/sys/client/submit-book', data);

// 提交
export const appointSubmit = (data) => post('/sys/user/submit-with-sms', data);
