import axios from 'axios';

// 请求配置实例
// https://lotterydraw.visualticket.cn/lucky 移动端用这个
// https://lotterydraw.visualticket.cn/lucky  抽奖用这个

const instance = axios.create({
  baseURL:
    process.env.NODE_ENV === 'development'
      ? '/api'
      : 'http://lotterydraw.visualticket.cn/lucky'
  // timeout: 5000,
});

// 请求拦截
instance.interceptors.request.use((config) => {
  return config;
});

// 响应拦截
instance.interceptors.response.use(async ({ data, config }) => {
  if (data) {
    if (!data.code || data.code === 200 || data.code === 500) {
      return data;
    }
  }
});

export default instance;

// get请求
export const get = (url, params, options) =>
  instance({
    ...options,
    url,
    params
  });

// post请求
export const post = (url, data, options) =>
  instance({
    ...options,
    method: 'post',
    url,
    data
  });
