import Vue from 'vue';
import VueRouter from 'vue-router';
// import HomeView from '../views/Home';
import BasicLayout from '@/layouts/BasicLayout';

Vue.use(VueRouter);

const routes = [
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: HomeView,
  //   meta: { title: '首页' } // 这个是 https://m.visualticket.cn/ 官网手机端
  // }
  {
    path: '/',
    component: BasicLayout,
    children: [
      {
        path: '/',
        name: 'LotteryDraw',
        meta: { title: '幸运抽奖' }, // 这个是 lotterydraw.visualticket.cn 抽奖， 部署的也是 首页
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/LotteryDraw/index.vue'
          )
      },
      {
        path: '/lottery-history',
        name: 'LotteryHistory',
        meta: { title: '我的奖品' },
        component: () =>
          import(
            /* webpackChunkName: "lotteryhistory" */ '../views/LotteryHistory/index.vue'
          )
      },
      {
        path: '/lottery-user',
        name: 'LotteryUser',
        meta: { title: '个人信息' },
        component: () =>
          import(
            /* webpackChunkName: "lotteryuser" */ '../views/LotteryUser/index.vue'
          )
      },
      {
        path: '/contact',
        name: 'Contact',
        meta: { title: '联系我们' }, // 这个是 联系我们  https://lotterydraw.visualticket.cn/contact
        component: () =>
          import(/* webpackChunkName: "Contact" */ '../views/Contact/index.vue')
      }
    ]
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router;
