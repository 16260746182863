<template>
  <div id="app">
    <!-- <nav>
      <router-link to="/"></router-link> |
      <router-link to="/about"></router-link>
    </nav>-->
    <router-view />
  </div>
</template>

<script>
import { WXaccredit, getUrlParam } from '@/utils/global';
export default {
  name: 'App',
  async created() {}
};
</script>

<style lang="less">
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
html,
body,
#app {
  height: 100%;
}
</style>
